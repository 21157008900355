import { observer } from "mobx-react";

import styles from "./questions.module.css";
import Question from "./Question";

function Questions() {
  const questions = [
    {
      title: "What is ClassX and how does it work?",
    },
    {
      title: "Who can sign up as an instructor or a host?",
    },
    {
      title: "How do I enroll in a course?",
    },
    {
      title: "How do I earn a certificate after completing a course?",
      content: [
        <p>
          Once you complete all the classes of a particular course, you’re
          eligible to receive a digital certification for your completion.
        </p>,
        <p>
          To receive this certificate, go to My Profile &gt; Courses &gt;
          Completed Courses &gt; And click on the completed Course. Once
          clicked, a pop-up window will appear showing you details about the
          course and its classes, along with a button that says{" "}
          <b>‘Download Digital Certificate’</b>. Click on that and it will open
          a .pdf file for you to download.
        </p>,
      ],
    },
    {
      title: "Can I cancel or reschedule a course?",
    },
    {
      title: "Are all courses in-person or are there online options?",
    },
    {
      title: "How can I contact support if I need help?",
    },
  ];

  return (
    <div className={styles.questions_content_content}>
      <div className="flex flex-col items-center gap-1.5 w-full text-center">
        <h4 className="text-body-large sm:text-heading lg:text-heading-medium font-body-large-bold sm:font-heading lg:font-heading-medium">
          <span className={styles.colored_span}>Questions?</span> Look here!
        </h4>
        <h3 className="text-body-medium sm:text-body-large font-body-medium sm:font-body-large">
          Some frequently asked questions answered for you
        </h3>
      </div>

      <div className={styles.questions_content_items}>
        {questions?.map((item: any, index: number) => (
          <Question key={index} question={item} />
        ))}
      </div>
    </div>
  );
}

export default observer(Questions);
