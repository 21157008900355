import { useEffect } from "react";

import styles from "./courses.module.css";
import {
  Footer,
  HomeComponents,
  Navbar,
  Playlist,
  SearchComponents,
} from "../../../components";
import { observer } from "mobx-react";
import { useStore } from "../../../store";
import { SearchBar } from "src/components/pages/home/Header/searchBar";

function Courses() {
  const { courseStore, userStore } = useStore();

  useEffect(() => {
    courseStore.getTopRatedCourses(4);
    courseStore.getDomains();
    courseStore.getCategories();
    if (!courseStore.isNew && courseStore.searchedCourses.length === 0) {
      courseStore.searchCourses(6, {});
    }
    userStore.getUser();
  }, [courseStore, userStore]);

  return (
    <div>
      <Navbar />

      <div className={styles.container}>
        <div className="flex justify-center">
          <SearchBar />
        </div>

        <HomeComponents.PopularCourses type="course" inSearch={true} />
        <hr />
        <HomeComponents.UpcomingCohorts page="search" />
        <HomeComponents.BecomeMember />
      </div>

      <Footer />
    </div>
  );
}

export default observer(Courses);
