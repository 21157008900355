interface partner {
  id: string;
  link: string;
  picture: string;
}

const partners: partner[] = [
  {
    id: "1",
    picture: "/images/partners/partner_1.png",
    link: "https://www.esprit.tn",
  },
  {
    id: "2",
    picture: "/images/partners/partner_2.svg",
    link: "https://unity.com",
  },
  {
    id: "3",
    picture: "/images/partners/partner_3.svg",
    link: "https://aws.amazon.com",
  },
  {
    id: "4",
    picture: "/images/partners/partner_4.svg",
    link: "https://www.google.com",
  },
  {
    id: "5",
    picture: "/images/partners/partner_5.png",
    link: "https://certnexus.com",
  },
  {
    id: "6",
    picture: "/images/partners/partner_6.svg",
    link: "https://www.comptia.org",
  },
  {
    id: "7",
    picture: "/images/partners/partner_7.png",
    link: "https://www.ooredoo.com",
  },
  {
    id: "8",
    picture: "/images/partners/partner_8.png",
    link: "https://www.sofrecom.com",
  },
  {
    id: "9",
    picture: "/images/partners/partner_9.png",
    link: "https://www.ebrd.com/starventure/start-ups",
  },
  {
    id: "10",
    picture: "/images/partners/partner_10.png",
    link: "https://www.gsma.com/mobilefordevelopment",
  },
  {
    id: "11",
    picture: "/images/partners/partner_11.png",
    link: "https://www.ifc.org/en/where-we-work/middle-east/promoting-inclusion/she-wins-arabia",
  },
  {
    id: "12",
    picture: "/images/partners/partner_12.png",
    link: "https://taqadam.kaust.edu.sa",
  },
];

export default partners;
