import React, { useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import ListItem from "./ListItem";
import { useStore } from "../../../../../store";
import { useNavigate } from "react-router-dom";

type SearchModalProps = {
  closePopup: () => void;
};

const courses = [
  {
    name: "Coding",
    description: "Learn to write programs",
  },
  {
    name: "Design",
    description: "Create stunning visuals",
  },
  {
    name: "Marketing",
    description: "Grow your brand’s presence",
  },
  {
    name: "Management",
    description: "Lead teams to success",
  },
  {
    name: "Photography",
    description: "Capture moments beautifully",
  },
  {
    name: "Writing",
    description: "Express your thoughts in words",
  },
  {
    name: "Music",
    description: "Learn to play instruments",
  },
  {
    name: "Cooking",
    description: "Master the art of cooking",
  },
  {
    name: "Fitness",
    description: "Stay fit and healthy",
  },
  {
    name: "Finance",
    description: "Manage your finances effectively",
  },
];

const icons = [
  "/images/icons/topics/coding.svg",
  "/images/icons/topics/design.svg",
  "/images/icons/topics/digital-marketing.svg",
  "/images/icons/topics/product.svg",
  "/images/icons/topics/life-skills.svg",
  "/images/icons/topics/data-science.svg",
  "/images/icons/topics/ai.svg",
];

const locations = [
  "Manhattan, New York",
  "Bronx, New York",
  "Brooklyn, New York",
  "Queens, New York",
  "Staten Island, New York",
  "Hoboken, New Jersey",
  "Jersey City, New Jersey",
  "Newark, New Jersey",
  "Long Island, New York",
  "Westchester, New York",
];

const SearchModal: React.FC<SearchModalProps> = ({ closePopup }) => {
  const navigate = useNavigate();
  const { courseStore } = useStore();

  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [location, setLocation] = useState("");
  const [course, setCourse] = useState("");
  const [activeTab, setActiveTab] = useState<"courses" | "locations">(
    "courses"
  );

  const getSelectedCourses = () => {
    return selectedCourses.length > 1
      ? `${selectedCourses.length} Selected`
      : selectedCourses[0];
  };

  const getSelectedLocations = () => {
    return selectedLocations[0].split(",")[0];
  };

  const reset = () => {
    setSelectedCourses([]);
    setSelectedLocations([]);
    setCourse("");
    setLocation("");
  };

  const handleSearch = (e: any) => {
    e.preventDefault();

    courseStore
      ?.searchCourses(6, {
        search: course,
        location: location,
        // categoryId: category,
      })
      .then(() => {
        closePopup();
        navigate("/search");
      });
  };

  return (
    <div className="fixed inset-0 z-20 w-full p-6 bg-primary-gray-50">
      <div className="relative body-medium-bold flex justify-center items-center mb-4">
        <h2>Search</h2>
        <img
          src="/images/icons/close.svg"
          className="absolute right-0 size-10"
          alt="menu"
          onClick={closePopup}
        />
      </div>

      <div
        className="mt-4 border border-primary-gray-200 rounded-xl p-5"
        onClick={() => setActiveTab("courses")}
        style={{ maxHeight: "calc(100% - 190px)", overflowY: "scroll" }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {activeTab !== "courses" && <img src="/images/icons/course.svg" />}
            <button
              className={
                activeTab === "courses" ? "body-large" : "body-medium-bold"
              }
            >
              Course
            </button>
          </div>
          {selectedCourses.length > 0 && (
            <div className="caption-text px-4 py-1 rounded-full bg-primary-paradise-pink-100 text-primary-paradise-pink-500">
              {getSelectedCourses()}
            </div>
          )}
        </div>
        <div className={`${activeTab === "courses" ? "block" : "hidden"}`}>
          <SearchInput
            value={course}
            setValue={setCourse}
            placeholder="Search for your passion"
          />
          <ul className="mt-4 space-y-2">
            {courses
              .filter((courseItem) =>
                courseItem.name.toLowerCase().includes(course.toLowerCase())
              )
              .map((course, index) => (
                <ListItem
                  key={index}
                  name={course.name}
                  icon={icons[index % icons.length]}
                  selectedItems={selectedCourses}
                  setSelectedItems={setSelectedCourses}
                />
              ))}
            {course &&
              !courses.some(
                (courseItem) =>
                  courseItem.name.toLowerCase() === course.toLowerCase()
              ) && (
                <ListItem
                  key="new-course"
                  name={course}
                  icon={icons[icons.length - 1]}
                  selectedItems={selectedCourses}
                  setSelectedItems={setSelectedCourses}
                />
              )}
          </ul>
        </div>
      </div>

      <div
        className="mt-4 border border-primary-gray-200 rounded-xl p-5"
        onClick={() => setActiveTab("locations")}
        style={{ maxHeight: "calc(100% - 190px)", overflowY: "scroll" }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {activeTab !== "locations" && (
              <img src="/images/icons/location-black.svg" />
            )}
            <button
              className={
                activeTab === "locations" ? "body-large" : "body-medium-bold"
              }
            >
              Location
            </button>
          </div>
          {selectedLocations.length > 0 && (
            <div className="caption-text px-4 py-1 rounded-full bg-primary-paradise-pink-100 text-primary-paradise-pink-500">
              {getSelectedLocations()}
            </div>
          )}
        </div>
        <div className={`${activeTab === "locations" ? "block" : "hidden"}`}>
          <SearchInput
            value={location}
            setValue={setLocation}
            placeholder="Find where it’s happening"
          />
          <ul className="mt-4 space-y-2">
            {locations
              .filter((locationItem) =>
                locationItem.toLowerCase().includes(location.toLowerCase())
              )
              .map((location, index) => (
                <ListItem
                  key={index}
                  name={location}
                  oneItem
                  icon="/images/icons/location-pink.svg"
                  selectedItems={selectedLocations}
                  setSelectedItems={setSelectedLocations}
                />
              ))}
          </ul>
        </div>
      </div>

      <div className="mt-5 flex justify-between items-center">
        <button className="body-medium-linked" onClick={reset}>
          Reset Changes
        </button>
        <button
          className="body-medium px-5 py-4 bg-primary-paradise-pink-500 text-white rounded-full flex items-center gap-2"
          onClick={handleSearch}
        >
          <img src="/images/icons/search.svg" />
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchModal;
