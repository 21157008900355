import React from "react";
import { observer } from "mobx-react";

import "./header.css";
import { Navbar } from "../../..";
import { SearchBar } from "./searchBar";

const Header: React.FC = () => {
  return (
    <div className="home_page_header">
      <Navbar defaultTransparent />

      <div className="home_page_header_gradient"></div>

      <div className="flex flex-col items-center gap-10 my-10">
        <div className="flex flex-col justify-center">
          <h1 className="header_title text-primary-paradise-pink-800 px-3.5">
            The <span className="colored_span">College Alternative</span>
            <br />
            you’ve been looking for.
          </h1>
          <p className="px-3.5 sm:px-0 text-sm font-normal text-primary-gray-400 mt-3.5 text-center">
            Get certified with{" "}
            <span className="font-bold">offline, in-person</span>{" "}
            <br className="block sm:hidden" />
            courses and jumpstart your career.
          </p>
        </div>

        <SearchBar />
      </div>
    </div>
  );
};

export default observer(Header);
