import React from "react";
import { Link } from "react-router-dom";

import styles from "./footer.module.css";

const Footer: React.FC = () => {
  const links = [
    {
      header: "Company",
      links: [
        { name: "Home", url: "https://classx.tech/about/" },
        { name: "About", url: "https://community.classx.tech/about/" },
        { name: "Blog", url: "https://blog.classx.tech/" },
        { name: "Careers", url: "https://www.linkedin.com/company/classx-tech" },
      ],
    },
    {
      header: "For People",
      links: [
        { name: "Teach with us", url: "https://community.classx.tech/teach-with-us" },
        { name: "Become a Host", url: "https://community.classx.tech/list-your-space/" },
        { name: "For investors", url: "https://community.classx.tech/investors/" },
        { name: "For Governments", url: "https://community.classx.tech/governments" },
        { name: "For Enterprises", url: "https://community.classx.tech/entreprises" },
      ],
    },
    {
      header: "Legal",
      links: [
        { name: "Terms of Service", url: "https://blog.classx.tech/terms/" },
        { name: "Privacy Policy", url: "https://blog.classx.tech/privacy-policy/" },
        { name: "Security", url: "https://blog.classx.tech/security/" },
      ],
    },
    {
      header: "Product",
      links: [
        { name: "Courses", url: "classx.tech/search" },
        { name: "Classes", url: "classx.tech/search" },
      ],
    },
    {
      header: "Account",
      links: [
        { name: "Sign in", url: "/login" },
        { name: "Create account", url: "/register" },
      ],
    },
  ];

  return (
    <footer className="relative">
      <div className={styles.wave}></div>
      <div className={styles.footer}>
        <img src="images/logo-white.svg" alt="Logo" />
        <div className="flex gap-2 sm:gap-4 mt-4 mb-9">
          <a
            href="https://www.instagram.com/classx.tech/"
            target="_blank"
            className="flex items-center rounded-full bg-primary-purple-800 p-3"
          >
            <img src="/images/icons/instagram-purple.svg" />
          </a>
          <a
            href="https://www.linkedin.com/company/classx-tech/"
            target="_blank"
            className="flex items-center rounded-full bg-primary-purple-800 p-3"
          >
            <img src="/images/icons/linkedin-purple.svg" />
          </a>
          <a
            href="https://www.facebook.com/@classx.tech"
            target="_blank"
            className="flex items-center rounded-full bg-primary-purple-800 p-3"
          >
            <img src="/images/icons/facebook-purple.svg" />
          </a>
          <a
            href="https://www.instagram.com/classx.tech/"
            target="_blank"
            className="flex items-center rounded-full bg-primary-purple-800 p-3"
          >
            <img src="/images/icons/x-purple.svg" />
          </a>
        </div>
        <div className="flex flex-col-reverse sm:flex-row justify-between gap-12 sm:gap-16">
          <div className="flex flex-wrap gap-7 lg:gap-12">
            {links.map((link, index) => (
              <div key={index} className="flex flex-col gap-4">
                <h4 className="body-small-bold text-primary-purple-200">
                  {link.header}
                </h4>
                <div className="body-small flex flex-col gap-2 text-primary-purple-100">
                  {link.links.map((item, i) => (
                    <Link key={i} to={item.url}>
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="body-small flex flex-col sm:text-end gap-4">
            <div className="flex items-center sm:justify-end gap-2.5">
              <img src="/images/icons/mail.svg" />
              <a
                href="mailto:hello@classx.tech"
                className="text-primary-purple-50"
              >
                hello@classx.tech
              </a>
            </div>
            <p className="text-primary-purple-200">
              ClassX Inc. 95 3rd St 2nd Floor, <br />
              San Francisco, California, <br />
              94103, United States
            </p>
          </div>
        </div>
        <p className="caption-text-bold text-primary-purple-50 text-center mt-16 lg:mt-[84px]">
          Copyright © 2024 All Rights Reserved by ClassX
        </p>
      </div>
    </footer>
  );
};

export default Footer;
