import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import env from "../../../../config/env";

import "./header.css";
import { useStore } from "../../../../store";
import Slider from "react-slider";
import { LocationInput } from "src/components/shared";
import SearchModal from "./MobileSearchPopup";
import ListItem from "./MobileSearchPopup/ListItem";

type Option = {
  value: string;
  label: string;
};

const MIN_PRICE = 0;
const MAX_PRICE = 250;
export const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const { courseStore } = useStore();

  const dropdownRef = useRef<any>(null);

  const categories = courseStore?.domains;
  const filter = courseStore.filter;

  const [search, setSearch] = useState<string>(filter?.search || "");
  const [location, setLocation] = useState<Option | null>(
    filter?.location || null
  );
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [category, setCategory] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isMobilePopupOpen, setIsMobilePopupOpen] = useState<boolean>(false);
  const [difficulty, setDifficulty] = useState<string>(filter.difficulty);
  const [priceRange, setPriceRange] = useState([
    filter.priceMin,
    filter.priceMax,
  ]);
  const [placeId, setPlaceId] = useState<string>("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdown]);

  const difficulties = ["Beginner", "Intermediate", "Advanced"];

  const icons = [
    "/images/icons/topics/coding.svg",
    "/images/icons/topics/design.svg",
    "/images/icons/topics/digital-marketing.svg",
    "/images/icons/topics/product.svg",
    "/images/icons/topics/life-skills.svg",
    "/images/icons/topics/data-science.svg",
    "/images/icons/topics/ai.svg",
  ];

  const handleSearch = (event: any) => {
    event.preventDefault();

    setLoading(true);

    let selectedDomainsIds: any[] = [];
    if (courseStore?.domains && courseStore?.domains.length > 0) {
      courseStore?.domains.forEach((domain: any) => {
        if (selectedDomains.includes(domain.name)) {
          selectedDomainsIds.push(domain.id.toString());
        }
      });
    }

    courseStore
      ?.searchCourses(6, {
        search: search,
        location: placeId,
        categoryId: category,
        difficulty: difficulty,
        priceMin: priceRange[0],
        priceMax: priceRange[1],
        domain: selectedDomainsIds,
      })
      .then(() => {
        navigate("/search");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPlaceSelected = async (value: any | null) => {
    setLocation(value);
    if (value && value.value.place_id) {
      setPlaceId(value.value.place_id);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    resetChanges();
  };

  const resetChanges = () => {
    setDifficulty("Intermediate");
    setPriceRange([30, 200]);
  };

  const applyFilter = () => {
    setIsPopupOpen(false);
  };

  const openMobilePopup = () => {
    if (window.innerWidth < 640) {
      setIsPopupOpen(false);
      setIsMobilePopupOpen(true);
    }
  };

  return (
    <>
      <div
        className="relative flex items-center border border-primary-gray-100 rounded-full bg-primary-gray-50 w-[350px] sm:w-[640px] h-[65px]"
        onClick={openMobilePopup}
      >
        {dropdown && (
          <ul
            className={`absolute top-[65px] left-[65px] border border-primary-gray-100 p-2.5 w-2/3 z-20 space-y-1 bg-primary-gray-50 custom_scrollbar`}
            style={{
              boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
            ref={dropdownRef}
          >
            {categories
              .filter((courseItem: any) =>
                courseItem.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((course: any, index) => (
                <ListItem
                  key={index}
                  name={course.name}
                  icon={icons[index % icons.length]}
                  selectedItems={selectedDomains}
                  setSelectedItems={setSelectedDomains}
                />
              ))}
            {search &&
              !categories.some(
                (courseItem: any) =>
                  courseItem.name.toLowerCase() === search.toLowerCase()
              ) && (
                <ListItem
                  key="new-course"
                  name={search}
                  icon={icons[icons.length - 1]}
                  selectedItems={selectedDomains}
                  setSelectedItems={setSelectedDomains}
                />
              )}
          </ul>
        )}

        <div className="flex justify-center items-center rounded-l-full bg-primary-paradise-pink-100 size-[65px]">
          <img
            className="cursor-pointer"
            src="/images/icons/filter-menu.svg"
            alt=""
            onClick={() => setIsPopupOpen(true)}
          />
        </div>

        <div className="py-1 px-1.5">
          <div
            className={`text-primary-paradise-pink-850 px-5 py-1 rounded-xl ${
              dropdown
                ? "border border-primary-paradise-pink-200 bg-primary-paradise-pink-50"
                : ""
            }`}
            onClick={() => setDropdown(true)}
          >
            <label
              className={`caption-text block ${
                dropdown ? "text-primary-paradise-pink-500" : ""
              }`}
              htmlFor="course"
            >
              Course
            </label>
            <input
              className="body-small bg-transparent focus:outline-none focus:ring-0"
              type="text"
              id="course"
              placeholder="Search for your passion"
              value={selectedDomains}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <span className="hidden sm:block h-[34px] w-[1px] mx-2.5 bg-black bg-opacity-10" />

        <div className="hidden sm:block text-primary-paradise-pink-850 w-1/3">
          <label className="caption-text block" htmlFor="location">
            Location
          </label>
          <GooglePlacesAutocomplete
            apiKey={env.GOOGLE_MAPS_API_KEY}
            selectProps={{
              onChange: onPlaceSelected,
              placeholder: "Find where it’s happening",
              value: location || undefined,
              noOptionsMessage: () => "Locations near you",
              styles: {
                input: (provided) => ({
                  ...provided,
                  width: "100%",
                  padding: "0",
                  margin: "0",
                }),
                indicatorsContainer: (provided) => ({
                  display: "none",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  width: "100%",
                  padding: "0",
                  // margin: "0",
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  width: "100%",
                  padding: "0",
                  cursor: "text",
                  margin: "0",
                }),
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  padding: "0",
                  margin: "0",
                  height: "0",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                  fontWeight: "400",
                  width: "100%",
                  padding: "0",
                  margin: "0",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  padding: "0",
                  margin: "0",
                }),
              },
              className: "body-small w-full",
            }}
          />
        </div>

        <div
          onClick={handleSearch}
          className="hidden sm:flex justify-center items-center ml-auto mr-2 rounded-full size-[50px] bg-primary-paradise-pink-500 cursor-pointer"
        >
          <img src="/images/icons/search.svg" alt="" />
        </div>
      </div>

      {isMobilePopupOpen && (
        <SearchModal closePopup={() => setIsMobilePopupOpen(false)} />
      )}

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        style={modalStyles}
      >
        <div className="w-[350px] sm:w-[464px]">
          <div className="flex itmes-center justify-between border-b border-primary-gray-100 p-5">
            <div className="body-medium-bold text-primary-purple-800">
              Filter
            </div>
            <img
              className="cursor-pointer"
              src="/images/icons/close-black.svg"
              alt=""
              onClick={closePopup}
            />
          </div>
          <div className="flex flex-col gap-6 p-5">
            <div className="body-small">
              <div className="text-primary-paradise-pink-800 mb-1">
                Difficulty
              </div>
              <div className="flex flex-wrap gap-1.5 text-primary-paradise-pink-500">
                {difficulties.map((diff) => (
                  <button
                    key={diff}
                    className={`px-7 py-2.5 rounded-xl cursor-pointer ${
                      difficulty === diff
                        ? "bg-primary-paradise-pink-100 border border-primary-paradise-pink-500"
                        : "bg-primary-paradise-pink-50"
                    }`}
                    onClick={() => setDifficulty(diff)}
                  >
                    {diff}
                  </button>
                ))}
              </div>
            </div>
            <div className="body-small">
              <div className="text-primary-paradise-pink-800 mb-1">
                Price Range
              </div>
              <div className="flex flex-col gap-1.5">
                <div className="caption-text flex justify-between text-primary-gray-500">
                  <span>Minimum</span>
                  <span>Maximum</span>
                </div>
                <div className="caption-text-bold flex justify-between text-primary-paradise-pink-850">
                  <div className="px-4 py-2 border border-primary-gray-200 rounded-xl">
                    <span>EUR</span>
                    <input
                      className="body-small text-primary-gray-800 w-[26px] ml-4"
                      type="number"
                      min={MIN_PRICE}
                      max={priceRange[1]}
                      value={priceRange[0]}
                      onChange={(e) =>
                        setPriceRange([parseInt(e.target.value), priceRange[1]])
                      }
                    />
                  </div>
                  <div className="px-4 py-2 border border-primary-gray-200 rounded-xl">
                    <span>EUR</span>
                    <input
                      className="body-small text-primary-gray-800 w-[26px] ml-4"
                      type="number"
                      min={priceRange[0]}
                      max={MAX_PRICE}
                      value={priceRange[1]}
                      onChange={(e) =>
                        setPriceRange([priceRange[0], parseInt(e.target.value)])
                      }
                    />
                  </div>
                </div>
                <div className="py-[15px]">
                  <Slider
                    className="slider"
                    min={MIN_PRICE}
                    max={MAX_PRICE}
                    value={priceRange}
                    onChange={setPriceRange}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center gap-5 mt-20">
              <div
                className="body-small-linked cursor-pointer"
                onClick={resetChanges}
              >
                Reset Changes
              </div>
              <div
                className="px-8 py-3 text-primary-paradise-pink-50 bg-primary-paradise-pink-900 rounded-xl cursor-pointer"
                onClick={applyFilter}
              >
                Apply Filter
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "12px",
    border: "1px solid var(--Primary-Gray-100, rgba(245, 245, 245, 1))",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "var(--Primary-Gray-50)",
    // margin: "20px",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "30",
  },
};
