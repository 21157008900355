import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./navbar.module.css";
import { Button } from "..";
import { useStore } from "../../../store";
import { observer } from "mobx-react";
import Beta from "./Beta";
import LanguageDropdown from "./LanguageDropdown";
import env from "../../../config/env";

const Navbar: React.FC<{ defaultTransparent?: boolean }> = ({
  defaultTransparent,
}) => {
  const navigate = useNavigate();
  const { userStore } = useStore();

  let user = userStore.user;
  let role = userStore.user?.roles[user?.roles.length - 1].name || "Student";
  let userRoles = user?.roles.map((role: any) => {
    return role.name;
  });
  const [dropdownItems, setDropdownItems] = useState<any[]>([
    {
      name: "My Courses",
      path: "/mySessions",
    },
    {
      name: "My Classes",
      path: "/mySessions",
    },
    {
      name: "Wishlist",
      path: "/favorites",
    },
    {
      name: "My Reviews",
      path: "/",
    },
    {
      name: "Settings",
      path: "/myprofile",
    },
  ]);

  const [language, setLanguage] = useState("EN");
  const [currency, setCurrency] = useState("EUR");
  const [showBeta, setShowBeta] = useState(true);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [transparent, setTransparent] = useState(defaultTransparent);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user) {
      userStore.getUser().then(() => {
        user = userStore.user;
        role = user?.roles[user?.roles.length - 1].name;
        userRoles = user?.roles.map((role: any) => {
          return role.name;
        });
        initDropDownItems();
      });
    } else {
      initDropDownItems();
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdownMenu]);

  const handleLogout = () => {
    userStore.logout();
    navigate("/");
  };

  const handleMobileLogout = () => {
    setShowSidebar(false);
    userStore.logout();
    navigate("/");
  };

  const initDropDownItems = () => {
    let items = [];

    if (userRoles && userRoles.length > 0) {
      if (role.toLowerCase() === "student") {
        items.push(
          {
            name: "My Courses",
            path: "/mySessions",
          },
          {
            name: "My Classes",
            path: "/mySessions",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "My Reviews",
            path: "/",
          },
          {
            name: "Settings",
            path: "/myprofile",
          }
        );
      }

      if (role.toLowerCase() === "teacher") {
        items.push(
          {
            name: "Manage Courses",
            path: "/teaching",
          },
          {
            name: "Manage Classes",
            path: "/teaching",
          },
          {
            name: "My Classes",
            path: "/mysessions",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "Settings",
            path: "/myprofile",
          }
        );
      }
      if (role.toLowerCase() === "host") {
        items.push(
          {
            name: "Manage Courses",
            path: "/teaching",
          },
          {
            name: "Manage Classrooms",
            path: "/classroomsManagement",
          },
          {
            name: "My Classes",
            path: "/mysessions",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "Settings",
            path: "/myprofile",
          }
        );
      }
      if (role.toLowerCase() === "admin") {
        items.push(
          {
            name: "Manage Courses",
            path: "/teaching",
          },
          {
            name: "Manage Classes",
            path: "/teaching",
          },
          {
            name: "Manage Classrooms",
            path: "/classroomsManagement",
          },
          {
            name: "My Courses",
            path: "/mySessions",
          },
          {
            name: "My Classes",
            path: "/mysessions",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "My Reviews",
            path: "/",
          },
          {
            name: "Settings",
            path: "/myprofile",
          }
        );
      }
    }

    setDropdownItems(items);
  };

  window.addEventListener("scroll", () => {
    setTransparent(window.scrollY <= 50 && defaultTransparent);
  });

  const capitalizeFirstLetter = (string: string) => {
    console.log("user role", string);

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Beta show={showBeta} setShow={setShowBeta} />
      {showBeta ? (
        <div className="h-[140px]"></div>
      ) : (
        <div className="h-[90px]"></div>
      )}
      <nav
        className={styles.navbar_container + (showBeta ? " top-12" : " top-0")}
        style={
          transparent
            ? { backgroundColor: "transparent" }
            : {
                backgroundColor: "#fff",
                borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
              }
        }
      >
        <div className="flex items-center gap-8">
          <Link to="/">
            <img className="h-7 sm:h-10" src="/images/logo.svg" alt="Logo" />
          </Link>
          <div className={`hidden ${user ? "" : "sm:block"}`}>
            <LanguageDropdown
              language={language}
              currency={currency}
              setLanguage={setLanguage}
              setCurrency={setCurrency}
            />
          </div>
        </div>

        {!user && (
          <div className={styles.navbar_links}>
            <Link to="/courses">Courses</Link>
            <a href="https://community.classx.tech/teach-with-us">
              Teach with us
            </a>
            <a href="https://community.classx.tech/list-your-space/">
              List your space
            </a>
            <a href="https://community.classx.tech/about/">About</a>
          </div>
        )}
        {user && role.toLowerCase() === "student" && (
          <div className={styles.navbar_links}>
            <Link to="/courses">Find Courses</Link>
            <Link to="/mySessions">My Courses</Link>
            <Link to="/mySessions">My Classes</Link>
          </div>
        )}

        {((user && role.toLowerCase() === "teacher") ||
          (user && role.toLowerCase() === "host") ||
          (user && role.toLowerCase() === "admin")) && (
          <div className={styles.navbar_links}>
            <Link to="/teaching">Manage</Link>
            <Link to="/courses">Find Courses</Link>
            <Link to="/mySessions">My Courses</Link>
            <Link to="/mySessions">My Classes</Link>
          </div>
        )}

        {!user ? (
          <>
            <div className={styles.navbar_buttons}>
              <Link to="/login" className={styles.navbar_button}>
                Sign In
              </Link>
              <Button
                text="Create an account"
                onClick={() => navigate("/register")}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-8 items-center">
              <div className={`hidden lg:flex items-center gap-8`}>
                <LanguageDropdown
                  language={language}
                  currency={currency}
                  setLanguage={setLanguage}
                  setCurrency={setCurrency}
                />
                <img
                  className="cursor-pointer"
                  src="/images/icons/wishlist.svg"
                  alt=""
                />
              </div>
              <div
                className={styles.navbar_user}
                onClick={() => setDropdownMenu(!dropdownMenu)}
              >
                <img
                  className={styles.navbar_avatar}
                  src={
                    user?.image && Object.keys(user?.image).length > 0
                      ? `${env.IMAGE_API}${user?.image?.name}`
                      : "images/default-user.svg"
                  }
                  alt="profile"
                />
              </div>

              {dropdownMenu && (
                <div className={styles.navbar_user_links} ref={dropdownRef}>
                  <div
                    className="flex items-center gap-2.5 p-3.5 border-b border-primary-gray-200 cursor-pointer"
                    onClick={() => navigate("/myprofile")}
                  >
                    <img
                      className={styles.navbar_avatar}
                      src={
                        user?.image && Object.keys(user?.image).length > 0
                          ? `${env.IMAGE_API}${user?.image?.name}`
                          : "images/default-user.svg"
                      }
                      alt="profile"
                    />
                    <div>
                      <div className="text-base text-primary-paradise-pink-900 font-normal">
                        {user.fullName}
                      </div>
                      <div className="text-xs leading-6 text-primary-purple-500 font-medium">
                        {capitalizeFirstLetter(role)} Account
                      </div>
                    </div>
                  </div>
                  <div className="py-2 border-b border-primary-gray-200 min-w-[258px] flex flex-col justify-start items-start space-y-2 pb-4">
                    {dropdownItems.map((item, index) => (
                      <div
                        className="text-sm text-primary-paradise-pink-800 py-2 px-5 font-normal"
                        key={index}
                      >
                        <Link to={item.path}>{item.name}</Link>
                      </div>
                    ))}
                  </div>
                  <div
                    className="flex gap-2 items-center text-sm font-normal text-tertiary-severe-600 pt-5 pb-5 px-5"
                    onClick={handleLogout}
                    style={{ cursor: "pointer" }}
                  >
                    <img src="/images/icons/logout.svg" alt="" />
                    <span>Log Out</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className={styles.navbar_mobile}>
          <img
            src={
              showSidebar ? "/images/icons/close.svg" : "/images/icons/menu.svg"
            }
            className="relative z-50 size-10 sm:size-12"
            alt="menu"
            onClick={() => setShowSidebar(!showSidebar)}
          />

          <div className="relative">
            <div
              className={`fixed inset-0 bg-black transition-opacity duration-200 ${
                showSidebar ? "opacity-20" : "opacity-0 pointer-events-none"
              }`}
            />
            <div
              className={`fixed top-0 right-0 flex justify-end w-screen transform transition-transform duration-200 ${
                showSidebar ? "translate-x-0" : "translate-x-full"
              }`}
              style={{ height: showBeta ? "calc(100vh - 3rem)" : "100vh" }}
            >
              <div className="flex flex-col justify-between w-80 h-screen p-6 bg-primary-gray-50">
                {!user && (
                  <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                    <Link className="py-0.5 px-1" to="/courses">
                      Courses
                    </Link>
                    <a
                      className="py-0.5 px-1"
                      href="https://community.classx.tech/teach-with-us"
                    >
                      Teach with us
                    </a>
                    <a
                      className="py-0.5 px-1"
                      href="https://community.classx.tech/list-your-space/"
                    >
                      List your space
                    </a>
                    <a
                      className="py-0.5 px-1"
                      href="https://community.classx.tech/about/"
                    >
                      About us
                    </a>
                    <div className="py-0.5 px-1">
                      <LanguageDropdown
                        language={language}
                        currency={currency}
                        setLanguage={setLanguage}
                        setCurrency={setCurrency}
                      />
                    </div>
                  </div>
                )}
                {user && role.toLowerCase() === "student" && (
                  <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                    <Link className="py-0.5 px-1" to="/mySessions">
                      My Courses
                    </Link>
                    <Link className="py-0.5 px-1" to="/mySessions">
                      My Classes
                    </Link>
                    <Link className="py-0.5 px-1" to="/favorites">
                      Wishlist
                    </Link>
                    <Link className="py-0.5 px-1" to="/">
                      My Reviews
                    </Link>
                    <Link className="py-0.5 px-1" to="/myprofile">
                      Settings
                    </Link>

                    <div className="py-0.5 px-1">
                      <LanguageDropdown
                        language={language}
                        currency={currency}
                        setLanguage={setLanguage}
                        setCurrency={setCurrency}
                      />
                    </div>
                  </div>
                )}
                {user && role.toLowerCase() === "teacher" && (
                  <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                    <Link className="py-0.5 px-1" to="/teaching">
                      Manage Courses
                    </Link>
                    <Link className="py-0.5 px-1" to="/teaching">
                      Manage Classes
                    </Link>
                    <Link className="py-0.5 px-1" to="/mySessions">
                      My Classes
                    </Link>
                    <Link className="py-0.5 px-1" to="/favorites">
                      Wishlist
                    </Link>
                    <Link className="py-0.5 px-1" to="/myprofile">
                      Settings
                    </Link>
                    <div className="py-0.5 px-1">
                      <LanguageDropdown
                        language={language}
                        currency={currency}
                        setLanguage={setLanguage}
                        setCurrency={setCurrency}
                      />
                    </div>
                  </div>
                )}

                {user && role.toLowerCase() === "host" && (
                  <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                    <Link className="py-0.5 px-1" to="/teaching">
                      Manage Courses
                    </Link>
                    <Link className="py-0.5 px-1" to="/classroomsManagement">
                      Manage Classrooms
                    </Link>
                    <Link className="py-0.5 px-1" to="/mySessions">
                      My Classes
                    </Link>
                    <Link className="py-0.5 px-1" to="/favorites">
                      Wishlist
                    </Link>
                    <Link className="py-0.5 px-1" to="/myprofile">
                      Settings
                    </Link>
                    <div className="py-0.5 px-1">
                      <LanguageDropdown
                        language={language}
                        currency={currency}
                        setLanguage={setLanguage}
                        setCurrency={setCurrency}
                      />
                    </div>
                  </div>
                )}

                {user && role.toLowerCase() === "admin" && (
                  <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                    <Link className="py-0.5 px-1" to="/teaching">
                      Manage Courses
                    </Link>
                    <Link className="py-0.5 px-1" to="/teaching">
                      Manage Classes
                    </Link>
                    <Link className="py-0.5 px-1" to="/classroomsManagement">
                      Manage Classrooms
                    </Link>
                    <Link className="py-0.5 px-1" to="/mySessions">
                      My Courses
                    </Link>
                    <Link className="py-0.5 px-1" to="/mySessions">
                      My Classes
                    </Link>
                    <Link className="py-0.5 px-1" to="/favorites">
                      Wishlist
                    </Link>
                    <Link className="py-0.5 px-1" to="/">
                      My Reviews
                    </Link>
                    <Link className="py-0.5 px-1" to="/myprofile">
                      Settings
                    </Link>
                    <div className="py-0.5 px-1">
                      <LanguageDropdown
                        language={language}
                        currency={currency}
                        setLanguage={setLanguage}
                        setCurrency={setCurrency}
                      />
                    </div>
                  </div>
                )}
                {!user && (
                  <div className="flex flex-col gap-2.5">
                    <Button
                      type="secondary"
                      text="Sign In"
                      onClick={() => navigate("/login")}
                    />
                    <Button
                      text="Create an account"
                      onClick={() => navigate("/register")}
                    />
                  </div>
                )}
                {user && (
                  <div className="flex flex-col gap-2.5">
                    <Button text="Logout" onClick={handleMobileLogout} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default observer(Navbar);
