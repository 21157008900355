"use client";
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import "../InstructorUpgrade/styles.css";
import "react-international-phone/style.css";
import {
  Input,
  Button,
  Navbar,
  DateRange,
  TimeRange,
  UploadImage,
  Loading,
  LocationInput,
} from "../../../components";
import MultiSelectInput from "../../../components/shared/MultiSelectInput";
import { toast, ToastContainer } from "react-toastify";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { newClassroom } from "src/store/Classroom";

function NewClassroom() {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [loading, setLoading] = useState(false);
  const [numberOfPlaces, setNumberOfPlaces] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [equipments, setEquipments] = useState([]);
  const [logo, setLogo] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [address, setAddress] = useState<any>();

  const fileUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("login");
      }
    });
  }, []);
  const handleAttachFile = (e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    const existingFileNames = files.map((file) => file.name);
    const filteredNewFiles = newFiles.filter(
      (file: File) => !existingFileNames.includes(file.name)
    );
    setFiles([...files, ...filteredNewFiles]);
  };
  const triggerAttachFile = (): void => {
    fileUploadRef.current?.click();
  };

  const onSave = async () => {
    setLoading(true);
    const classroom = {
      logo,
      name,
      size: numberOfPlaces,
      description,
      equipments,
      from: startDate,
      to: endDate,
      startTime,
      endTime,
      profileId: userStore.user?.id,
      album: [],
      address: { name: address.label, area: address.label, city: address.label, state: address.label },
      location: { lat: address.lat, lng: address.lng },
    };

    files.forEach(async (file) => {
      await userStore.uploadPicture(file).then((response) => {
        classroom.album = classroom.album.concat(response);
      });
    });

    if (logo) {
      await userStore.uploadPicture(logo).then((response) => {
        classroom.logo = response;
      });
    }

    await newClassroom(classroom)
      .then((response) => {
        console.log(response);
        setLoading(false);
        navigate("/classroomsManagement");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="main">
      <ToastContainer theme="dark" />
      <Navbar />
      <h1 className="title">Add a new classroom</h1>
      <form>
        <section>
          <h2>Classroom Information</h2>
          <div className="inputs">
            <div className="left-column">
              <div className="field">
                <label htmlFor="logo">Logo (optional)</label>
                <div className="input-field">
                  <UploadImage image={logo} setImage={setLogo} />
                </div>
              </div>
              <div className="field">
                <label htmlFor="loaction">Location</label>
                <div className="input-field">
                  <div className="input">
                    <LocationInput
                      location={address}
                      setLocation={setAddress}
                    />
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="places">Number of places (Maximum)</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="number"
                      label=""
                      fullWidth
                      value={numberOfPlaces}
                      onChange={(e) => setNumberOfPlaces(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="date">Available date</label>
                <div className="input-field">
                  <div className="input">
                    <DateRange
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="date">Available time</label>
                <div className="input-field">
                  <div className="input">
                    <TimeRange
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-column">
              <div className="field">
                <label htmlFor="name">Name</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="text"
                      label=""
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="field">
                <label htmlFor="description">Description</label>
                <div className="input-field">
                  <textarea
                    className="bio"
                    id="description"
                    placeholder="Tell us about this classroom"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <MultiSelectInput
                  text="Available Equipments"
                  name="equipments"
                  onValueChange={setEquipments}
                />
              </div>

              <div className="field">
                <label>Classroom Pictures</label>
                <div className="input-field" id="files">
                  <div className="files">
                    {files.map((file, index) => (
                      <div className="file" key={index}>
                        <img
                          className="document"
                          src="/images/document.png"
                          alt="doc"
                        />
                        <p>{file.name}</p>
                        <img
                          className="trash"
                          src="/images/trash.png"
                          alt="trash"
                          onClick={() =>
                            setFiles(files.filter((f, i) => i !== index))
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <input
                    className="fileInput"
                    type="file"
                    ref={fileUploadRef}
                    multiple
                    onChange={(e) => handleAttachFile(e)}
                  />
                  <Button
                    text="+ Attach file"
                    type="secondary"
                    width="200px"
                    customColor="rgb(117, 116, 116)"
                    onClick={triggerAttachFile}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault
            onClick={() => navigate(-1)}
          />
          <Button
            text="Save"
            type="primary"
            width="400px"
            preventDefault
            onClick={onSave}
          />
        </div>
      </form>
    </div>
  );
}
export default observer(NewClassroom);
