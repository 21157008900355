import React from "react";

type ListItemProps = {
  name: string;
  icon: string;
  oneItem?: boolean;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
};

const ListItem: React.FC<ListItemProps> = ({
  name,
  icon,
  oneItem,
  selectedItems,
  setSelectedItems,
}) => {
  const isSelected = selectedItems.includes(name);

  const handleSelect = () => {
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item: any) => item !== name));
    } else {
      if (oneItem) {
        setSelectedItems([name]);
        return;
      }
      setSelectedItems([...selectedItems, name]);
    }
  };

  return (
    <li
      onClick={handleSelect}
      className={`p-2 border border-primary-gray-50 rounded-2xl cursor-pointer ${
        isSelected
          ? "bg-primary-paradise-pink-50 border-primary-paradise-pink-500"
          : "border-primary-gray-50"
      }`}
    >
      <div className="flex items-center space-x-3">
        <div
          className={`p-4 bg-primary-paradise-pink-50 rounded-lg flex items-center justify-center`}
        >
          <img src={icon} alt="" />
        </div>
        <p className="body-small">{name}</p>
      </div>
    </li>
  );
};

export default ListItem;
