import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./feedbacks.module.css";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";

const FeedbackItem: React.FC<{ feedback: any }> = ({ feedback }) => {
  return (
    <div className="flex flex-col items-center gap-4 px-5 py-4 border border-black border-opacity-5 bg-primary-gray-50 rounded-2xl h-fit mb-5 sm:m-0">
      <div className="flex flex-col items-center gap-2">
        <img
          className="rounded-full fit-cover"
          src={feedback.image}
          alt={feedback.name}
          width={84}
          height={84}
        />
        <div className="flex flex-col items-center gap-0.5">
          <div className="body-large">{feedback.name}</div>
          <div className="caption-text text-primary-paradise-pink-600">
            {feedback.profession}
          </div>
          {feedback.verified && (
            <div className="flex gap-0.5 items-center caption-text-bold text-tertiary-success-700">
              <img src="/images/icons/success-green.svg" />
              <span>Verified Student</span>
            </div>
          )}
        </div>
      </div>

      <p className="body-small text-center">{feedback.feedback}</p>

      <div className="flex gap-0.5">
        {new Array(Math.ceil(feedback.rating)).fill(null).map((_, i) => (
          <img key={i} src="/images/icons/star.svg" />
        ))}
      </div>
    </div>
  );
};

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
  return (
    <button
      className={`custom-dot h-[3px] rounded-full mx-1.5 transition-all duration-300 ${
        active
          ? "active bg-primary-paradise-pink-800 w-6"
          : "bg-primary-gray-300 w-5"
      }`}
      onClick={onClick}
    />
  );
};

const Feedbacks: React.FC = () => {
  const navigate = useNavigate();

  const feedbacks = [
    {
      name: "Julian Howard",
      profession: "Graphic Designer",
      verified: true,
      feedback:
        "The fun approach to learning new things really made a difference. I’ve picked up various new skills fast!",
      rating: 5,
      image: "https://randomuser.me/api/portraits/men/1.jpg",
    },
    {
      name: "Steve Makaeli",
      profession: "Digital Marketer",
      verified: true,
      feedback:
        "The course was practical and straight to the point. Highly recommend!",
      rating: 5,
      image: "https://randomuser.me/api/portraits/men/2.jpg",
    },
    {
      name: "Liam Parker",
      profession: "UX Designer",
      verified: true,
      feedback:
        "The fun approach to learning new things really made a difference. I’ve picked up various new skills fast!",
      rating: 5,
      image: "https://randomuser.me/api/portraits/men/3.jpg",
    },
    {
      name: "Olivia Hayes",
      profession: "Product Manager",
      verified: false,
      feedback:
        "Loved the real-world approach. Got my certificate quickly and smoothly.",
      rating: 5,
      image: "https://randomuser.me/api/portraits/women/1.jpg",
    },
    {
      name: "Nathan Smith",
      profession: "Software Developer",
      verified: true,
      feedback:
        "Super easy to find the right course. The instructors were top-notch!",
      rating: 5,
      image: "https://randomuser.me/api/portraits/men/4.jpg",
    },
    {
      name: "Sophia Bennet",
      profession: "Data Analyst",
      verified: true,
      feedback:
        "ClassX made learning new skills so convenient. I’m already applying them at work.",
      rating: 5,
      image: "https://randomuser.me/api/portraits/women/2.jpg",
    },
    {
      name: "Ava Mitchell",
      profession: "HR Manager",
      verified: false,
      feedback:
        "Great experience! The classes were engaging, and I felt well-supported.",
      rating: 5,
      image: "https://randomuser.me/api/portraits/women/3.jpg",
    },
  ];

  const responsive = {
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <div className="mt-10">
      <div className={styles.gradient}></div>
      <div className={styles.feedbacks}>
        <div className="flex justify-center items-center gap-4 p-2.5">
          <img src="/images/icons/star2.svg" />
          <h3 className="text-body-large font-body-large-bold sm:text-subheading sm:font-subheading text-center">
            What our students say
          </h3>
          <img src="/images/icons/star1.svg" />
        </div>

        <div className="block sm:hidden">
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            arrows={false}
            showDots
            itemClass="px-2"
            partialVisbile
            containerClass="px-4"
            customDot={<CustomDot />}
          >
            {feedbacks.map((feedback, index) => (
              <FeedbackItem key={index} feedback={feedback} />
            ))}
          </Carousel>
        </div>

        <div className={styles.feedbacks_list}>
          {feedbacks.map((feedback, index) => (
            <FeedbackItem key={index} feedback={feedback} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(Feedbacks);
