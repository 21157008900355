import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./partners.module.css";
import { partners } from "../../../../constants";

const Partners: React.FC = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 3,
    },
  };

  return (
    <div className={styles.partners}>
      <div className="relative">
        <div className={styles.gradient}></div>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          arrows={false}
          itemClass="flex items-center px-4"
        >
          {partners.map((item) => (
            <a key={item.id} href={item.link} target="_blank" rel="noreferrer">
              <img
                alt="partner"
                loading="lazy"
                src={item.picture}
                width={150}
                style={{
                  objectFit: "contain",
                }}
              />
            </a>
          ))}
        </Carousel>
      </div>
      <div className="flex justify-center">
        <div className="body-medium text-primary-paradise-pink-500 bg-primary-paradise-pink-50 rounded-full px-7 py-2.5 w-fit">
          Our Partner Enterprises
        </div>
      </div>
    </div>
  );
};

export default Partners;
