import React, { useState } from "react";

import { observer } from "mobx-react";

interface IProps {
  show?: boolean;
  setShow: (prop: boolean) => void;
}

const Beta: React.FC<IProps> = ({ show, setShow }) => {
  return (
    <>
      {show && (
        <div className="fixed top-0 w-full h-12 flex items-center justify-center p-4 z-50 bg-primary-purple-850">
          <p className="text-white text-sm font-inter font-normal">
            We’re in Beta, your{" "}
            <span className="text-primary-purple-400 underline cursor-pointer">
              feedback
            </span>{" "}
            matters to us!
          </p>
          <img
            className="size-6 right-4 sm:absolute cursor-pointer"
            src="/images/icons/close-white.svg"
            alt=""
            onClick={() => setShow(false)}
          />
        </div>
      )}
    </>
  );
};

export default observer(Beta);
